import React, { useEffect, useState } from "react";
import "./Calendar.css";
import { Button, Spin, } from 'antd';
import Settings from "../../../shared/config/Settings";
import getUserId from "../../../shared/core/GetToken";
import { notification } from 'antd';
import moment from 'moment';

function CalendarComponent({ startingMonth, startingYear, endingMonth, endingYear, setDateState, Pid, typeid, selectedDateFromC, locids, setSelectedReasonForVisitId, selectedReasonForVisitId, LookUpList, openNotificationWithIcon }) {
    // const [selectedDateFromC, setDateState] = useState(new Date());
    const [nextDateLoading, setNextDateLoading] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(
        new Date(startingYear, startingMonth - 1, 1)
    );
    const [gotoDate, setGotoDate] = useState("");

    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December",];

    const monthStart = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    const monthEnd = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
    const daysInMonth = monthEnd.getDate();
    const firstDayOfMonth = monthStart.getDay();

    useEffect(() => {
        if (new Date(selectedDateFromC)?.toDateString() === new Date().toDateString()) {
            setCurrentMonth(new Date(startingYear, startingMonth - 1, 1))
        }
    }, [selectedDateFromC])

    const daysArray = [];
    for (let j = 1; j <= daysInMonth; j++) {
        daysArray.push(j);
    }

    const selectDate = (date) => {
        setDateState(date);
    };

    const isCurrentDate = (date) => {
        const today = new Date();
        return (
            today.getDate() === date.getDate() &&
            today.getMonth() === date.getMonth() &&
            today.getFullYear() === date.getFullYear()
        );
    };

    const isSelectableDate = (date) => {
        const today = new Date();
        return date >= today;
    };

    const isWithinRange = (date) => {
        const startMonth = new Date(startingYear, startingMonth - 1, 1);
        const endMonth = new Date(endingYear, endingMonth, 0);
        return date >= startMonth && date <= endMonth;
    };

    const moveMonth = (direction) => {
        if (direction === "prev") {
            const prevMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1);
            if (isWithinRange(prevMonth)) {
                setCurrentMonth(prevMonth);
            }
        } else if (direction === "next") {
            const nextMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1);
            if (isWithinRange(nextMonth)) {
                setCurrentMonth(nextMonth);
            }
        }
    };

    const handleGotoDateChange = (event) => {
        setGotoDate(event.target.value);
    };

    const handleGotoDate = () => {
        const newDate = new Date(gotoDate);
        if (isWithinRange(newDate)) {
            setDateState(newDate);
            setCurrentMonth(newDate);
        }
    };

    const nextavialablecallincalendercomponent = () => {
        setNextDateLoading(true)
        getUserId().then((userInfo) => {
            const item = JSON.parse(userInfo);
            var myHeaders = new Headers();
            myHeaders.append("Token", item.token)
            myHeaders.append("Content-Type", "application/json")
           
            let date = moment(selectedDateFromC).format("YYYY-MM-DD");
            var raw = JSON.stringify({
                PatientID: item.userid,
                ProviderID: Pid,
                AppointmentTypeID: typeid,
                StartDate: date,
                LocationIds:locids.join()
            })
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: raw
            };
            
            fetch(Settings.apiUrl + "Patient/NextAvailableDay", requestOptions)
                .then(response => response.json())
                .then(result => {
                    var finalData = replaceAllBackSlashM(result)
                    let res = JSON.parse(finalData)
                    if (res.length) {
                        // setSelectedReasonForVisitId(25)
                        setDateState(new Date(res[0].RosterDate))
                        setCurrentMonth(new Date(res[0].RosterDate))
                    }
                    else {
                        notification["warning"]({
                            message: 'Notification',
                            description:
                                'No more slots available for this provider',
                        });
                    }
                    setNextDateLoading(false)
                })
                .catch(error => {
                    setNextDateLoading(false)
                });
        })
    }
    const replaceAllBackSlashM = (targetStr) => {
        var index = targetStr.indexOf("\\");
        while (index >= 0) {
            targetStr = targetStr.replace("\\", "");
            index = targetStr.indexOf("\\");
        }
        return targetStr;
    }
    return (
        <div className='col-md-12'>
            <div className="calendar">
                <div className="calendar-header">
                    <div className='calendar-month'>
                        <div  onClick={() => moveMonth("prev")} className='cursor-pointer'> 
                            <img src={`assets/images/${isWithinRange(new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1)) ? 'previous' : 'previous-disable'}.svg`} className='w-h-32px' />
                          </div>
                        <h3 className='mb-0'>
                            {monthNames[currentMonth.getMonth()]?.substring(0, 3)} {currentMonth.getFullYear()}
                        </h3>

                        {/* payloadqueryString=`?PatientID=${itemUserInfo.parentid}&CardType=${cardkindName}&cardCharges=0&amount=${amount}&notes=${invoiceNote}`; */}
                        <div  onClick={() => moveMonth("next")} className='cursor-pointer'>
                            <img src={`assets/images/${isWithinRange(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)) ? 'next' : 'next-disable'}.svg`} className='w-h-32px' />
                        </div>
                    </div>

                    <div className='button-next-available'>
                        <button className='btn btn-next-available-day btn-sm pull-right w-auto cursor-pointer margin-right-eight' onClick={nextavialablecallincalendercomponent}>{"Next Available Day "} {nextDateLoading ? <Spin className='spin-appointment text-white' /> : null }</button>
                        <span></span>
                    </div>
                </div>

                <div className='calendar-container'>
                    {daysArray.map((day, index) => (

                        <div className={
                            selectedDateFromC &&
                                selectedDateFromC.getDate() === day &&
                                selectedDateFromC.getMonth() === currentMonth.getMonth() &&
                                selectedDateFromC.getFullYear() === currentMonth.getFullYear()
                                ? "day-container selected-date"
                                : isCurrentDate(
                                    new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day)
                                )
                                    ? "day-container current-date"
                                    : isSelectableDate(
                                        new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day)
                                    )
                                        ? "day-container selectable-date"
                                        : "day-container unselectable-date"
                        } onClick={() =>
                            selectDate(new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day))
                        }>
                            <div className="day-header">{days[(index + firstDayOfMonth) % 7]}</div>
                            <div>{day}</div>
                        </div>
                    ))}
                </div>
             
            
               
            </div>
        </div>

    );
}
export default CalendarComponent;