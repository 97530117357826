import { Button, Modal } from "antd";
import { async } from "q";
import React, { useEffect, useState } from "react";
import { View, Text, Alert } from "react-native";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Settings from "../../shared/config/Settings";
import getUserId from "../../shared/core/GetToken";
import { actionAPI } from "../../shared/reduxToolkit/actionAPI";
// react-scripts --openssl-legacy-provider start
const AppointmentPaymentSuccessRoute = () => {
  const [paymentData, setPaymentData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appointmentBookingSuccessful, setAppointmentBookingSuccessful] = useState(false);
  const [isCallFromWeb, setisCallFromWeb] = useState(false);
  const [isCallFromAppointment, setIsCallFromAppointment] = useState(false);
  const [paymentMethod, setpaymentMethod] = useState(null);
  const [paymentChecking, setPaymentChecking] = useState("loading");
  const [bookingAppointmentRequest, setBookingAppointmentRequest] = useState(false);

  const navigate = useNavigate()
  let appointmentPayload
  const search = useLocation().search
  const apiDispatcher = useDispatch()

  const writeToFileLog = (payload11) => {
    try {

      fetch(Settings.apiUrl + '/Patient/WriteToFile?data=' + decodeURI(payload11 + `${new Date()}`) + '', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          console.log(data);
          // Do something with the retrieved data
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
    catch (e) {
      console.log(e)
    }
  }
  const bookAppointmentAPi = async (token, patientid, apiUrl, payload) => {
    var myHeaders = new Headers();
    myHeaders.append("Token", token)
    myHeaders.append("Content-Type", "application/json")
    setBookingAppointmentRequest(true)
    var raw = {
      PatientID: patientid,
      AppointmentTypeID: payload.typeid != '' ? payload.typeid : null,
      SlotID: payload.slotid,
      Reason: payload.reason,
      ReasonForVisitID: payload.reasonId,
      PatientID2: patientid,
      StartTime: payload.startTime,
      EndTime: payload.endTime,
      Duration: payload.duration

    }
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(raw)
    };

    fetch(apiUrl + "/api/Patient/AppointmentV2", requestOptions)
      .then(data => data.json())
      .then(json => {
        writeToFileLog(`Step Booking: PID:${patientid} AppointmentV2 call end`)
        setBookingAppointmentRequest(false);
        if (json == "Appointment has been booked successfully") {
          setAppointmentBookingSuccessful(true)
          setPaymentChecking("success")
          if (isCallFromWeb) {
            apiDispatcher(actionAPI.GetAppSettingAPI())
            window.history.pushState('', '', '/appointpaymentsuccess?checked=True');
          }
        }
        else {
          alert("Appointment Booking Failed")

        }
      })
      .catch(err => console.log(err))



  }
  const getTempPaymentSessionIDApi = async (token, patientid, temppaymentid, apiUrl) => {
    writeToFileLog(`Step 2: PID:${patientid} GetTempPaymentSessionID call start`)

    setPaymentChecking("loading")
    var myHeaders = new Headers();
    myHeaders.append("Token", token);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify({
        TempPaymentID: temppaymentid
      })
    };

    const getTempPaymentSession = await fetch(apiUrl + "/api/Patient/GetTempPaymentSessionID", requestOptions)
    const apiresponse = await getTempPaymentSession.json()

        writeToFileLog(`Step 2: PID:${patientid} GetTempPaymentSessionID call end`)

    console.log("GetTempPaymentSessionID response", JSON.stringify(apiresponse))

    if (paymentMethod == "windcave") {

      if (JSON.parse(JSON.parse(apiresponse?.RepeatPayload)?.key2)) {

        appointmentPayload = JSON.parse(apiresponse?.RepeatPayload)?.key2
        appointmentPayload = JSON.parse(appointmentPayload)
      }
    } else {
      if (JSON.parse(apiresponse?.RepeatPayload)?.split("&appointment=")[1]) {

        appointmentPayload = JSON.parse(apiresponse?.RepeatPayload)?.split("&appointment=")[1]
        appointmentPayload = JSON.parse(appointmentPayload)
      }
    }
    setPaymentData(apiresponse)
    return apiresponse?.PaymentSessionID

  }
  const getStripeTransactionBySessionApi = async (token, paymentSessionID, patientid, apiUrl) => {

    var myHeaders = new Headers();
    myHeaders.append("Token", token);
    myHeaders.append("Content-Type", "application/json");
    var raw2 = JSON.stringify({
      "SessionID": paymentSessionID,
      "PatientID": patientid,

    });
    // alert('slss' + appointmentPayload)       
    writeToFileLog(`Step 3: PID:${patientid} GetStripeTransactionBySession call start`)

    var requestOptions2 = {
      method: 'POST',
      headers: myHeaders,
      body: raw2,
      redirect: 'follow'
    };
    const getStripeTransBySession = await fetch(apiUrl + "/api/Patient/GetStripeTransactionBySession", requestOptions2)
    const json = await getStripeTransBySession.json()

        writeToFileLog(`Step 3: PID:${patientid} GetStripeTransactionBySession call end`)
        console.log("GetStripeTransactionBySession response ", json)

        return json

  }
  const savePayment2Api = async (patientid, payloadqueryStringFinal, token, apiUrl) => {
    writeToFileLog(`Step 4: PID:${patientid} SavePayment2 call start`)

    const savePayment2 = await fetch(apiUrl + "/api/Patient/SavePayment2" + payloadqueryStringFinal, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Token": token,
      },
      redirect: 'follow',
    })
    const result = await savePayment2.json()

        writeToFileLog(`Step 4: PID:${patientid} SavePayment2 call end`)

        setPaymentChecking("success")
        return result

  }
  const getWindCaveSessionByIdApi = async (token, patientid, sessionId, apiUrl) => {
    setPaymentChecking("loading")
    var myHeaders = new Headers();
    myHeaders.append("Token", token);
    myHeaders.append("Content-Type", "application/json");
    writeToFileLog(`Step 9: PID:${patientid} GetWindCaveSessionById call start`)


    let raw = {
      "PatientID": patientid,
      "SessionID": sessionId
    }
    console.log(raw)
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(raw)
    };

    const getWindcaveSession = await fetch(apiUrl + "/api/Patient/GetWindCaveSessionById", requestOptions)
    const json = getWindcaveSession.json();

    if (json) {

      writeToFileLog(`Step 9: PID:${patientid} GetWindCaveSessionById call end`)

      let paymentApproved = true
      json.transactions?.map(item => {
        if (item.responseText !== "APPROVED") {
          paymentApproved = false
        }
      })
      console.log("paymentApproved", paymentApproved)
      if (paymentApproved) {
        return json
      } else {
        setPaymentChecking("failed")
        return
      }
    }

  }
  useEffect(async () => {
    function parseQuery(queryString) {
      const params = {};
      const queryStringWithoutQuestionMark = queryString.substring(1);
      const queryParams = queryStringWithoutQuestionMark.split("&");
      queryParams.forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = decodeURIComponent(value);
      });
      return params;
    }
    try {



      const queryString = window.location.search;
      let { apiUrl, pid, patienttoken, paymenttype, callfrom, IsRxRequest, IsFromAppointment, temppaymentid, token, patientid, abctick, sessionId, checked } = parseQuery(queryString);
      writeToFileLog(`Step 1: PID:${patientid}`)

      setIsCallFromAppointment(true)

      if (checked === "True") {
        setPaymentChecking("success")
        setAppointmentBookingSuccessful(true)
      }
      if (callfrom == "mobile") {
        setisCallFromWeb(false)
      } else {
        setisCallFromWeb(true)
      }
      setpaymentMethod(paymenttype)
      // alert(window.location.search);
      debugger
      apiUrl = abctick;
      // alert(apiUrl)
      setTimeout(async () => {

        if (callfrom == "mobile" && paymenttype == "stripe") {
          debugger
          let paymentSessionID = await getTempPaymentSessionIDApi(token, patientid, temppaymentid, apiUrl)
          if (typeof (paymentSessionID) === "string") {

            let stripeTransactionData = await getStripeTransactionBySessionApi(token, paymentSessionID, patientid, apiUrl)
            if (stripeTransactionData) {

              const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}`;
              const savePaymentResult = await savePayment2Api(patientid, payloadqueryStringFinal, token, apiUrl)

              if (savePaymentResult && appointmentPayload) {
                // openNotificationWithIcon('success', 'Payment succeeded successfully')
                // alert("inside resullt and repelapayloadddd")
                setPaymentChecking("success")
                writeToFileLog(`Step Booking: PID:${patientid} AppointmentV2 call start`)
                bookAppointmentAPi(token, patientid, apiUrl, appointmentPayload)
              }
              else if (savePaymentResult) {
                setPaymentChecking("success")
                setAppointmentBookingSuccessful(true)
                // setIsModalOpen(true)
              } else {
                // openNotificationWithIcon('error', 'Payment unsuccessful')
              }
            }
          } else {
            setPaymentChecking("failed")
          }

        }
        else if (callfrom == "mobile" && paymenttype == "poli") {
          token = patienttoken;

          let paymentSessionID = await getTempPaymentSessionIDApi(token, patientid, temppaymentid, apiUrl)

          if (typeof (paymentSessionID) === "string") {

            // if (repeatJsonTID && parseInt(repeatJsonTID) > 0) {
            if (temppaymentid) {
              const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}`;
              const savePaymentResult = await savePayment2Api(patientid, payloadqueryStringFinal, token, apiUrl)
              if (savePaymentResult && appointmentPayload) {
                // openNotificationWithIcon('success', 'Payment succeeded successfully')
                setPaymentChecking("success")
                writeToFileLog(`Step Booking: PID:${patientid} AppointmentV2 call start`)
                bookAppointmentAPi(token, patientid, apiUrl, appointmentPayload)

              }
              else if (savePaymentResult) {
                setPaymentChecking("success")
                // window.history.pushState('', '', '/appointpaymentsuccess?checked=True');
                setAppointmentBookingSuccessful(true)

              }

            }

            setPaymentChecking("success")
            // alert("Accountssss")
            return;
          } else {
            setPaymentChecking("failed")
          }


        }
        else if (callfrom == "mobile" && paymenttype == "windcave") {
          const paymentSessionID = await getTempPaymentSessionIDApi(token, patientid, temppaymentid, apiUrl)
          if (paymentSessionID) {

            const windcaveSessionResponse = await getWindCaveSessionByIdApi(token, patientid, paymentSessionID, apiUrl)
            if (windcaveSessionResponse) {

              const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}`;

              const savePaymentResult = await savePayment2Api(patientid, payloadqueryStringFinal, token, apiUrl)
              if (savePaymentResult) {

                bookAppointmentAPi(token, patientid, apiUrl, appointmentPayload)
              }

            }
          }



        }
        else if (callfrom == "web" && paymenttype == "poli") {
          token = patienttoken;

          async function ftPaymentCall() {
            let appointmentPayload
            const item = await getUserId().then((userInfo) => {
              return JSON.parse(userInfo);
            });
            let paymentSessionID = await getTempPaymentSessionIDApi(token, patientid, temppaymentid, apiUrl)
            if (typeof (paymentSessionID) === "string") {

              if (temppaymentid) {

                const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}`

                const savePaymentResult = await savePayment2Api(patientid, payloadqueryStringFinal, token, apiUrl)
                if (savePaymentResult && appointmentPayload) {
                  // openNotificationWithIcon('success', 'Payment succeeded successfully')
                  setPaymentChecking("success")
                  writeToFileLog(`Step Booking: PID:${patientid} AppointmentV2 call start`)
                  bookAppointmentAPi(token, patientid, apiUrl, appointmentPayload)
                }
                else if (savePaymentResult) {
                  setPaymentChecking("success")
                  window.history.pushState('', '', '/appointpaymentsuccess?checked=True');
                  setAppointmentBookingSuccessful(true)
                  // setTimeout(() => {
                  //   navigate("/accounts")
                  // }, 2000)

                  // setIsModalOpen(true)
                } else {
                  // openNotificationWithIcon('error', 'Payment unsuccessful')

                }

              }
              else {
                // openNotificationWithIcon('error', 'Payment unsuccessful')
              }

            } else {
              setPaymentChecking("failed")
            }


          }
          try {
            ftPaymentCall();
          }
          catch (error) {
            console.log('error', error)
          }
        }
        else if (callfrom == "web" && paymenttype == "stripe") {
          const item = await getUserId().then((userInfo) => {
            return JSON.parse(userInfo);
          });
          let paymentSessionID = await getTempPaymentSessionIDApi(item.token, patientid, temppaymentid, apiUrl)



          if (typeof (paymentSessionID) === "string") {
            let stripeTransactionData = await getStripeTransactionBySessionApi(item.token, paymentSessionID, item.userid, apiUrl)

            if (stripeTransactionData) {
              const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}`;
              const savePaymentResult = await savePayment2Api(item.userid, payloadqueryStringFinal, item.token, apiUrl)


              if (savePaymentResult && appointmentPayload) {

                setPaymentChecking("success")
                writeToFileLog(`Step Booking: PID:${patientid} AppointmentV2 call start`)
                bookAppointmentAPi(token, patientid, apiUrl, appointmentPayload)
              }
              else if (savePaymentResult) {
                setPaymentChecking("success")
                window.history.pushState('', '', '/appointpaymentsuccess?checked=True');

                setAppointmentBookingSuccessful(true)
                // setTimeout(() => {
                //   navigate("/accounts")
                // }, 2000)
                // setIsModalOpen(true)
              } else {
                // openNotificationWithIcon('error', 'Payment unsuccessful')

              }

            }



          } else {
            setPaymentChecking("failed")
          }

        }
        else if (callfrom == "web" && paymenttype == "windcave") {
          // {
          //   "SessionID": "000003000187955303d4b14c52e6bb4b",
          //     "PatientID": "8A006891-86E9-45DA-9170-865473AA5DC5"
          // }

          const item = await getUserId().then((userInfo) => {
            return JSON.parse(userInfo);
          });
          let paymentSessionID = await getTempPaymentSessionIDApi(item.token, item.userid, temppaymentid, apiUrl)
          if (paymentSessionID) {

            const windcaveSessionResponse = await getWindCaveSessionByIdApi(item.token, item.userid, paymentSessionID, apiUrl)
            if (windcaveSessionResponse) {


              const payloadqueryStringFinal = `?PatientID=${patientid}&electronicPaymentID=${temppaymentid}`;

              const savePaymentResult = await savePayment2Api(patientid, payloadqueryStringFinal, item.token, apiUrl)
              if (appointmentPayload && savePaymentResult) {
                writeToFileLog(`Step Booking: PID:${patientid} AppointmentV2 call start`)
                bookAppointmentAPi(token, patientid, apiUrl, appointmentPayload)
              } else {
                window.history.pushState('', '', '/appointpaymentsuccess?checked=True');

              }

            }
          }


        }
      }, 5000)
    } catch (error) {
      writeToFileLog(`Payment error: ${error}`)

    }

  }, [])

  return (
    <div className="page-content-wrapper-inner">

      <div className="content-viewport">
        <div className="row row-margin-zero h-100 text-center d-flex justify-content-center">
          {isCallFromWeb ?
            <h1 className='mt-5 w-75 max-width-400'>{(paymentMethod === "poli" && !paymentData) || (paymentChecking === "loading") ?
              <>
                <img src="assets/images/processing.svg" className="mb-3" />
                <h2>Payment Processing</h2>
              </>

              : bookingAppointmentRequest ?
                <>
                  <img src="assets/images/repeating.svg" className="mb-3" />
                  <h2>Booking Your Appointment</h2>
                </>
                : (paymentMethod === "poli" && paymentData?.TransactionStatus?.toLowerCase() == "completed") || paymentChecking === "success" ?
                  <>
                    <img src="assets/images/Success.svg" className="mb-3" />
                    <h2>Appointment Booked Successfully</h2>
                    {isCallFromAppointment ? appointmentBookingSuccessful ?
                      <div>
                        <p className="font-size-17">
                          Your payment is successful and your appointment is booked.
                        </p>
                        <p className="font-size-17">
                          You can check the status of your appointment under upcomming appointments.</p>
                      </div>
                      :
                      <div>

                        <p className="font-size-17">Your request failed.
                        </p>
                        <p className="font-size-17">
                          Please try again.
                        </p>
                        <p className="font-size-17">
                          If the problem persists, contact your practice for further help.</p>
                      </div>
                      : null}
                    <button className="payment-returnpage-btn mt-5" onClick={() => {
                      localStorage.setItem("appointmentRedirectedOnce", false)
                      navigate("/appointments?AppointmentBookingSuccess=True")
                    }}>Continue</button>
                  </>
                  : paymentChecking === "pending" ?
                    <>
                      <img src="assets/images/failed.svg" className="mb-3" />
                      <h2>Payment is still processing at stripe end.</h2>
                      <button className="payment-returnpage-btn mt-5" onClick={() => {
                        localStorage.setItem("appointmentRedirectedOnce", false)
                        navigate("/appointments?AppointmentBookingSuccess=True")
                      }}>Continue</button>
                    </>
                    : <>
                      <img src="assets/images/failed.svg" className="mb-3" />
                      <h2>Request Failed</h2>
                      <button className="payment-returnpage-btn mt-5" onClick={() => {
                        if (isCallFromAppointment) {
                          localStorage.setItem("appointmentRedirectedOnce", false)
                          navigate("/medications?RepeatSuccess=True")
                        } else {
                          navigate("/accounts")

                        }
                      }}>Continue</button>
                    </>}</h1>
            :

            <h1 className='mt-5 w-75 max-width-400'>{(paymentMethod === "poli" && !paymentData) || (paymentChecking === "loading") ? <>
              <img src="assets/images/processing.svg" className="mb-3" />
              <h2>Payment Processing</h2>
            </> : bookingAppointmentRequest ? <>
                <img src="assets/images/repeating.svg" className="mb-3" />
              <h2>Booking Your Appointment</h2>
            </>
              : (paymentMethod === "poli" && paymentData?.TransactionStatus?.toLowerCase() == "completed") || paymentChecking === "success" ?
                <>
                  <img src="assets/images/Success.svg" />
                  <h2>Request Successful</h2>
                    {isCallFromAppointment == false ?
                      <div>
                        <p className="font-size-17">
                          Your payment is successful.
                        </p>
                        <p className="font-size-17">
                          Please tap on back arrow shaped button on top left corner to proceed further.</p>
                      </div>
                      :
                      isCallFromAppointment ? appointmentBookingSuccessful ? <div>
                        <p className="font-size-17">
                          Your payment is successful and your appointment is booked.</p>
                        <p className="font-size-17">
                          You can check the status of your appointment under upcomming appointments. </p>
                        <p className="font-size-17">
                          Please tap on back arrow shaped button on top left corner to proceed further. </p>
                      </div> : <p>Your request failed. Please try again. If the problem persists, contact your practice for further help.</p> : null
                    // {/* <button className="payment-returnpage-btn mt-5" onClick={() => { isCallFromAppointment ? navigate("/medications") : navigate("/accounts") }}>Continue</button> */}
                  }
                </>
                :
                paymentChecking !== "idle" && paymentChecking === "pending" ?
                  <>
                      <img src="assets/images/failed.svg" className="mb-3" />
                    <h2>Payment is still processing at stripe end.</h2>
                    <button className="payment-returnpage-btn mt-5" onClick={() => {

                      localStorage.setItem("appointmentRedirectedOnce", false)
                      navigate("/appointments?AppointmentBookingSuccess=True")
                    }}>Continue</button>
                  </>
                  : paymentChecking !== "idle" && <>
                      <img src="assets/images/failed.svg" className="mb-3" />
                    <h2>Request Failed</h2>
                    {/* <button className="payment-returnpage-btn mt-5" onClick={() => { isCallFromAppointment ? navigate("/medications") : navigate("/accounts") }}>Continue</button> */}
                  </>
            }</h1>}
        </div>
      </div>
      <Modal
        open={isModalOpen}
        title={appointmentBookingSuccessful ? 'Payment Successful' : 'Payment Failed'}
        maskClosable={false}
        closable={false}
        cancelButtonProps={{ style: { display: "none" } }}
        afterClose={() => {
          localStorage.setItem("appointmentRedirectedOnce", false)
          navigate("/appointments?AppointmentBookingSuccess=True")
        }}
        onOk={() => { setIsModalOpen(false) }}
      >
        {appointmentBookingSuccessful ? <p>
          Your payment is successful and your appointment is booked. You can check the status of your appointment under upcomming appointments.</p> : <p>Your request failed. Please try again. If the problem persists, contact your practice for further help.</p>}
      </Modal>
    </div>
  );
};


export default AppointmentPaymentSuccessRoute;
