import { Button } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Settings from '../../shared/config/Settings';

const AppointmentPaymentCancel = () => {
  const [isCallFromWeb, setisCallFromWeb] = useState(false);
  const navigate = useNavigate()
  const writeToFileLog = (payload11) => {
    try {

      fetch(Settings.apiUrl + '/Patient/WriteToFile?data=' + decodeURI(payload11 + `${new Date()}`) + '', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          return response.json();
        })
        .then(data => {
          console.log(data);
          // Do something with the retrieved data
        })
        .catch(error => {
          console.error('There was a problem with the fetch operation:', error);
        });
    }
    catch (e) {
      console.log(e)
    }
  }
  useEffect(() => {
    // setTimeout(() => {
    function parseQuery(queryString) {
      const params = {};
      const queryStringWithoutQuestionMark = queryString.substring(1);
      const queryParams = queryStringWithoutQuestionMark.split("&");
      queryParams.forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = decodeURIComponent(value);
      });
      return params;
    }
    writeToFileLog(`Step 1: Payment Cancelled page `)

    const queryString = window.location.search;
    let { callfrom } = parseQuery(queryString);
    if (callfrom == "mobile") {
      setisCallFromWeb(false)
      // alert(queryString)
    } else {
      setisCallFromWeb(true)
    }

    // }, 2000)
  }, [])
  return (
    <>
      <div className="page-content-wrapper-inner">
        <div className="content-viewport">
          <div className="row row-margin-zero h-100 text-center d-flex justify-content-center">
            {isCallFromWeb ? (
              <div className="w-75 max-width-400 d-flex flex-column align-items-center payment-returnpage-main-div">
                <img className="mb-5" src="assets/images/declined.svg" />
                <h2 className="">Payment Cancelled</h2>
                <p className="font-size-17">
                  Payment is cancelled by user. Please click go back button to
                  proceed further.
                </p>
                <button
                  className="payment-returnpage-btn mt-5"
                  onClick={() => {
                    navigate("/appointments")

                  }}
                >
                  Go Back
                </button>
              </div>
            ) : (
              <div className="w-75 max-width-400 d-flex flex-column align-items-center payment-returnpage-main-div">
                <img className="mb-5" src="assets/images/declined.svg" />
                <h2 className="">Payment Cancelled</h2>
                <p className="font-size-17">
                  Payment is cancelled by user. Please tap on back arrow button to proceed further.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AppointmentPaymentCancel
