import React, { useEffect,useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import AppointmentHandler from "../components/AppointmentHandler";
import FutureAppointmentView from "./AppointmentTabs/FutureAppointmentView";
import CompleteAppointmentView from "./AppointmentTabs/CompleteAppointmentView";
import MissedAppointmentView from "./AppointmentTabs/MissedAppointmentView";
import CompleteAppointmentViewcopy from "./AppointmentTabs/CompleteAppointmentViewcopy";
import SearchAppointmentView from "./AppointmentTabs/SearchAppointmentView";
import { validatePatientTokenFromApi } from "../../shared/actions/TokenValidActions";


import { View } from "react-native-web";
import { Tabs } from "antd";
import 'antd/dist/antd.css';
import renderHTML from 'react-render-html';
import GetAppSettings from '../../shared/core/GetAppSettings';
import NewUpdatedAppointment from './AppointmentTabs/NewUpdatedAppointment';
import { fetchfAppointmentsFromApi } from '../../shared/actions/FutureAppointActions';
import { actionAPI } from '../../shared/reduxToolkit/actionAPI';
const { TabPane } = Tabs;
const Appointments = props => {

    const [isloaded, setIsload] = React.useState(false);
    const { ProxyMenu, isLoadingMenu } = useSelector(state => state.ProxyMenu);
    const navigate = useNavigate()
    const { TokenValidator, isChecking } = useSelector(state => state.ValidateToken);
    const [routeid, setRoute] = React.useState("1")
    const [portalSetting, setPortalSetting] = useState(null);
    const apiDispatcher = useDispatch()
    useEffect(() => {
        GetAppSettings().then((info) => {
            const set = JSON.parse(info)
            setPortalSetting(set)
        })
    }, [])
   
    useEffect(() => {
        //props.validateToken();
        if (props?.location?.state && props?.location?.state?.id != undefined) {
            //   alert(props.location.state.id)
            setRoute(props?.location?.state?.id)
            //  getMessageById(props.location.state.id)
        } else {
            //  alert("Teri merbani")
            setRoute("1")
            // history.push('/emails')
        }
    }, [])
    useEffect(() => {
        if (ProxyMenu != null && ProxyMenu != undefined) {
            let itemExists = false
            ProxyMenu.map((item, index) => {
                if (item.resource.extension[0].valueString == 'Appointments') {
                    itemExists = true
                }

            })
            if (!itemExists) {
                navigate("/")
            }
        }
    }, [ProxyMenu])

    React.useEffect(() => {
        apiDispatcher(actionAPI.checkToken(navigate))
    }, [])


    return (
      
                    <div className="page-content-wrapper-inner">
                        <div className="viewport-header">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb has-arrow">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Appointments</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="content-viewport">
                            
                            <div className="row row-margin-zero h-100">
                                <div className="col-12 h-100">
                                    <div className="grid h-100">
                                        <div className="grid-body h-100">
                                            {/* <h2 className="grid-title">Appointments</h2> */}
                                {/* props.location.state && props.location.state.id != undefined ? "2" */}
                                            <div className="item-wrapper appointment-container">
                                    <Tabs defaultActiveKey={"1"} centered items={[
                                                    {
                                            label:
                                                <div className='py-2 px-1'>

                                                    Book Appointment
                                                </div>,
                                                    key: "1",
                                            children: <NewUpdatedAppointment />,


                                                },
                                                    {
                                                        label: <div className='py-2 px-1' onClick={() => {

                                                            apiDispatcher(actionAPI.GetFutureAppointFromAPI({ pageSize: 100, pageNumber: 1 }))
                                                    
                                                }}>Upcoming Appointment</div>,
                                                    key: "2",
                                                    children: <FutureAppointmentView/>
                                                },
                                                    {
                                                        label: <div className='py-2 px-1'>History</div>,
                                                    key: "3",
                                                    children: <SearchAppointmentView/>
                                                },

                                                ]} >
                                                    {/* <TabPane tab="Book Appointment" key="1">
                                                        {/* <NewAppointment /> */}
                                                        {/* <NewUpdatedAppointment />
                                                    </TabPane>
                                                    <TabPane tab="Upcoming Appointment(s)" key="2" >
                                                        <FutureAppointmentView />
                                                    </TabPane>

                                            <TabPane tab="History" key="3"> */}
                                                        {/* <CompleteAppointmentView /> */}
                                                        {/* <SearchAppointmentView />
                                                    </TabPane>  */}

                                                    {/* <TabPane tab="Missed" key="3">
                                                        <MissedAppointmentView />
                                                    </TabPane>

                                                    <TabPane tab="Cancelled" key="4">
                                                        <CompleteAppointmentViewcopy />
                                                    </TabPane>

                                                    <TabPane tab="Search" key="5">
                                                        <SearchAppointmentView />
                                                    </TabPane> */}


                                                </Tabs>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

              
    )
}


function mapStateToProps(state) {
    return {
        TokenValidator: state.TokenValidator,
        ProxyMenu: state.ProxyMenu,
    }
}
function mapDispatchToProps(disptach) {
    return {
        
        validateToken: () => disptach(validatePatientTokenFromApi()),
        getAppoint: (pageSize, pageNumber) => disptach(fetchfAppointmentsFromApi(pageSize, pageNumber)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Appointments)
// export default
